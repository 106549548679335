import React, { useState } from 'react'
import img1 from "../image/img1.png"
import img2 from "../image/img2.png"
import img3 from "../image/img1.jpg"
import img4 from "../image/img2.jpg"
import img5 from "../image/img3.png"
import Navi from '../navi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'


const Home = () => {
    
  
    return (
    <div>

               <center>                
                    <div className='Home-cnt-01'>
                        <div className='Home-cnt-01-sub-01'>
                            <strong>sta<span>W</span>ro</strong>
                            <hr/>
                        </div>
                        <div className='Home-cnt-01-sub-02'>
                            <div className='Home-cnt-01-sub-02-sub-01'>                    
                                <h2 className='Home-cnt-01-sub-02-sub-01-h2-01'>Win <span className='Home-cnt-01-sub-02-sub-01-h2-01-span-01'>₹500</span> in just <span className='Home-cnt-01-sub-02-sub-01-h2-01-span-02'>2</span> minutes!</h2>
                                <div className='Home-cnt-01-sub-02-sub-01-sub-cnt-01'>
                                    <button onClick={()=>{window.location.href="/play"}}>Play now!</button>
                                </div>
                                <div className='Home-cnt-01-sub-02-sub-01-sub-cnt-02'>
                                    <strong>Play and Get Reward$</strong>
                                </div>

                            </div>

                            <div className='Home-cnt-01-sub-02-sub-02'>
                                <img src={img2} alt='img' />
                            </div>

                        </div>
                    </div>

                    <div className='Home-cnt-02'>

                        <div className='Home-cnt-02-sub-01'>
                            <div className='Home-cnt-02-sub-01-sub-01'>
                                <img src={img3} alt='img' />
                            </div>
                            <div className='Home-cnt-02-sub-01-sub-02'>
                                <strong>This game is based on questions from general knowledge, mental ability, math, news, and more.</strong>
                            </div>

                        </div>



                        <div className='Home-cnt-02-sub-01'>
                            <div className='Home-cnt-02-sub-01-sub-01'>
                                <img src={img4} alt='img' />
                            </div>
                            <div className='Home-cnt-02-sub-01-sub-02'>
                                <strong>We will credit the amount using UPI payments within 24 hours.</strong>
                            </div>

                        </div>

                        <div className='Home-cnt-02-sub-01'>
                            <div className='Home-cnt-02-sub-01-sub-01'>
                                <img src={img5} alt='img' />
                            </div>
                            <div className='Home-cnt-02-sub-01-sub-02'>
                                <strong>Includes Amazon coupons, Flipkart coupons, Zomato coupons, and more.</strong>
                            </div>

                        </div>



                    </div>


                </center>
                
    </div>
  )
}

export default Home
