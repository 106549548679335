import React, { useState } from 'react';
import apiAdmin from '../pages/adminapi';
import Naviba from './naviba';

const Qstallcheck = () => {
  const [lang, setLang] = useState('');
  const [qdata, setQData] = useState([]);

  const GetListsQuest = (e) => {
    e.preventDefault();
    apiAdmin
      .get(`${process.env.REACT_APP_API_URL}/get/admin/all/question/lists/${lang}`)
      .then((res) => {
        if (res.data.data) {
          setQData(res.data.data);
        } else if (res.data.Logout === 'OUT') {
          localStorage.removeItem('token');
          window.location.reload();
        } else {
          alert('Unexpected response received');
        }
      })
      .catch((error) => {
        console.log(error);
        alert('Something went wrong');
      });
  };

  // Populate Array2 with question numbers from qdata
  const Array2 = qdata.map((user) => parseInt(user.qno));

  // Populate Array1 with a range of numbers from 1 to the length of qdata
  const Array1 = Array.from({ length: qdata.length }, (_, i) => i + 1);

  // Find remaining data that's in Array2 but not in Array1
  const remaining_data = Array2.filter((value) => !Array1.includes(value));

  console.log(remaining_data);

  return (
    <div>
      <center>
        <div className="Home-cnt-01-sub-01">
          <strong>sta<span>W</span>ro</strong>
          <hr />
        </div>
        <br />

        <form onSubmit={GetListsQuest}>
          <select onChange={(e) => setLang(e.target.value)} value={lang}>
            <option value="">Select</option>
            <option value="Kannada">Kannada</option>
            <option value="English">English</option>
            <option value="Hindi">Hindi</option>
            <option value="Telugu">Telugu</option>
          </select><br />

          <button type="submit" className="admin_questionview-btn-01">Get</button>
        </form>
        <br />

        <h2 className="admin_questionview-h1-02">
          Total Questions: <strong>{qdata.length}</strong>
        </h2>

        <div className="admin_qstallcheck-main-cnt-01">
          {qdata.map((user, i) => {
            const data = parseInt(user.qno);
            return (
              <div
                key={data}
                className={Array1.includes(data) ? "admin_qstallcheck-main-cnt-01-main-cnt-01" : "admin_qstallcheck-main-cnt-01-main-cnt-02"}
              >
                <span className="admin_qstallcheck-main-cnt-01-main-cnt-01-sub-01">
                  {Array1.includes(data) ? (
                    <strong>{user.qno} : Found</strong>
                  ) : (
                    <strong> {i + 1} : Not Found</strong>
                  )}
                </span>
              </div>
            );
          })}
        </div><br/>

        <h2 className="admin_questionview-h1-02">
          Total Questions: <strong>{remaining_data.length}</strong>
        </h2>

        <div className="admin_qstallcheck-remaining-cnt">
          {remaining_data.length > 0 ? (
            remaining_data.map((num, i) => (
              <div key={i} className='admin_qstallcheck-main-cnt-01-main-cnt-01'>
                <span className='admin_qstallcheck-main-cnt-01-main-cnt-01-sub-01'>{num} <strong>: Found</strong> </span>
              </div>
            ))
          ) : (
            <span>All questions are accounted for.</span>
          )}
        </div>
      </center>
      <Naviba />
    </div>
  );
};

export default Qstallcheck;
