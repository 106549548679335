import React, { useEffect, useState } from 'react'

const Cupons = () => {

    const [cupon_data, setCupon_Data] = useState([]);
    const [userData, setUserData] = useState([]);


    useEffect(()=>{
        GetCupon()
    },[])

    useEffect(() => {
        cupon_data.forEach((data, index) => {
          fetch(`${process.env.REACT_APP_API_URL}/get/singel/user/won/data/${index+1}`)
            .then(res => res.json())
            .then(data => {
              if (data.data) {
                setUserData(prevState => [...prevState, { id: index, ...data.data }]);
              }
            })
            .catch(error => {
              console.log(error);
            });
        });
      }, [cupon_data]);
      

    const GetCupon = () =>{
        fetch(`${process.env.REACT_APP_API_URL}/get/cupon/get/all/datas`)
        .then(res => res.json())
        .then(data =>{
            if(data.data){
                setCupon_Data(data.data)
            }else{

            }
        }).catch(error =>{
            console.log(error)
        })
    }

  return (
    <div>
        <center>
            <div className='Home-cnt-01-sub-01'>
                <strong>sta<span>W</span>ro</strong>
                <hr/>
            </div>

            <h1 className='cupons_h1-01'>Cupons</h1>

            <div className="cart-page-main-cnt-02-sub-cnt-01">
            {cupon_data.map((data, index) => {
                const fetchedData = userData.find(user => user.id === index);

                return (
                    <div key={index} className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01'>
                    <strong className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-strong-01'>{data.title}</strong>
                    <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-01'>
                        <img src={data.img} alt='img' />
                    </div><br/>
                    <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-01'><strong>{data.body}</strong></span><br/>
                    <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-02'>Rank : <strong>{index + 1}</strong></span><br/>
                    <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-02'>
                        <span>{fetchedData ? fetchedData.username : ''}</span>
                    </div>
                    </div>
                );
                })}
            </div>

        </center>     
    </div>
  )
}

export default Cupons
