import axios from 'axios';
import React, { useState } from 'react'
import Popup from '../pages/popup';

const QuestionAdd = () => {

    // user, img, Questio, a, b, c, d, Ans, lang

    const user = localStorage.getItem("username");

    const [img, setImg] = useState('');
    const [Questio, setQuestio] = useState([]);
    const [a, setA] = useState([]);
    const [b, setB] = useState([]);
    const [c, setC] = useState([]);
    const [d, setD] = useState([]);
    const [Ans, setAns] = useState([]);
    const [lang, setLang] = useState([]);

    const [data, setData] = useState([]);
    const [alert, setAlert] = useState(false);

    const PostData = (e) =>{
        setAlert(false);
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_API_URL}/get/posted/count/questions`,{user, img, Questio, a, b, c, d, Ans, lang})
        .then(res =>{
            if(res.data.Status === "OK"){
                const qno = res.data.qno
                setData(`Question No : ${qno}  Posted Successfully`);
                setAlert(true);
            }else{
                setData("Something Went Wrong");
                setAlert(true);
            }
        })
        .catch(error =>{
            console.log(error);
        })
    }

  return (
    <div>
      <center>
        <div className='Home-cnt-01-sub-01'>
            <strong>sta<span>W</span>ro</strong>
            <hr/>
        </div>
        <br/>
        <h1 className='admin_questionadd-h1-01'><span>Add</span> Questions</h1>

        <div className='admin_questionadd-cnt-01'>
            <form onSubmit={PostData}>
                <textarea placeholder='Type a Question' onChange={e=>{setQuestio(e.target.value)}} required></textarea><br/>
                <input type='url' placeholder='Image URL' onChange={e=>{setImg(e.target.value)}} autoCorrect='on' autoComplete='off' /><br/>
                <input type='text' placeholder='Option A' onChange={e=>{setA(e.target.value)}} autoCorrect='on' autoComplete='off' required /><br/>
                <input type='text' placeholder='Option B' onChange={e=>{setB(e.target.value)}} autoCorrect='on' autoComplete='off' required /><br/>
                <input type='text' placeholder='Option C' onChange={e=>{setC(e.target.value)}} autoCorrect='on' autoComplete='off' required /><br/>
                <input type='text' placeholder='Option D' onChange={e=>{setD(e.target.value)}} autoCorrect='on' autoComplete='off' required /><br/>
                <select required onChange={e=>{setAns(e.target.value)}} >
                    <option value=''>Answer</option>
                    <option value='A'>A</option>
                    <option value='B'>B</option>
                    <option value='C'>C</option>
                    <option value='D'>D</option>
                </select><br/>
                <select required onChange={e=>{setLang(e.target.value)}} >
                    <option value=''>Language</option>
                    <option value='Kannada'>Kannada</option>
                    <option value='English'>English</option>
                    <option value='Hindi'>Hindi</option>
                    <option value='Telugu'>Telugu</option>
                </select><br/>
                <button type='submit' >Post</button>
            </form>
            

        </div>
      </center>
      {alert &&
        <Popup data={data} val={alert} />
      }
    </div>
  )
}

export default QuestionAdd
