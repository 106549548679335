import React,{useState, useEffect} from 'react'
import api from '../pages/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import Popup from '../pages/popup'
import apiAdmin from '../pages/adminapi'
import Naviba from './naviba'

const Claimdreques = () => {

    const [pending_data, setPending_data] = useState([])
    const [alert, setAlert] = useState(false);
    const [data, setData] = useState([])

    const user = localStorage.getItem("user")
  
    useEffect(()=>{
      GetPending()
    },[])
  
    const GetPending = () =>{
      apiAdmin.get(`${process.env.REACT_APP_API_URL}/get/requested/coins/admin`)
      .then(res =>{
        if(res.data.data){
          setPending_data(res.data.data);
        }else if(res.data.Logout === "OUT"){
          localStorage.removeItem("ssid");
          window.location.reload()
        }else{
  
        }
      })
      .catch(error =>{
        console.log(error)
      })
    }

  return (
    <div>
      <center>
        <div className='Home-cnt-01-sub-01'>
            <strong>sta<span>W</span>ro</strong>
            <hr/>
        </div>
        <br/>

        <div className='claimdreq-page-h1-01'>
            <h1>Cons Request <FontAwesomeIcon icon={faBell} className='claimdreq-page-h1-01-icon-01' /> <span>{pending_data.length}</span></h1>
        </div>

        <div className='carthist-cnt-01-sub-cnt-01'>
            {pending_data.map((user, i)=>{

                const delT = (e) =>{
                    setAlert(false)
                    e.preventDefault();
                    axios.delete(`${process.env.REACT_APP_API_URL}/find/by/id/and/delete/req/coins/${user._id}`)
                    .then(res =>{
                        if(res.data.Status === "OK"){
                            GetPending()
                            setData("Paid")
                            setAlert(true)
                        }else{
                            setData("Something went Wrong Try again")
                            setAlert(true)
                        }
                    }).catch(error =>{
                        console.log(error)
                    })
                }

                return(
                    <div key={i} className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01'>
                        <strong className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-strong-01'>{user.title}</strong>
                        <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-01'>
                            <img src={user.img} alt='img' />
                        </div><br/>
                        <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-01'>Valid : <strong>{user.valid}</strong></span><br/>
                        <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-02'>Stars : <strong>{user.stars}</strong></span><br/>
                        <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-02'>
                            {/* <strong style={{color : "white"}}>Pending.!</strong> */}
                            <button className='admin-btn-01' onClick={delT} >Paid</button>
                        </div>
                    </div>
                )
            })}
        </div>
        <div style={{height : "50px"}}>

        </div>
        {alert &&
            <Popup data={data} val={alert} />
        }

      </center>
      <Naviba />
    </div>
  )
}

export default Claimdreques
