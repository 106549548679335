import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/home';
import Signup from './pages/signup';
import Login from './pages/login';
import Forgotpass from './pages/forgotpass';
import Changepass from './pages/changepass';
import Error from './error';
import Navi from './navi';
import Account from './pages/account';
import Ac_upi from './pages/Account/ac_upi';
import History from './pages/Account/history';
import Pending from './pages/Account/pending';
import Payment from './pages/Account/payment';
import Cart from './pages/cart';
import Carthist from './pages/carthist';
import Settings from './pages/settings';
import Updatepass from './pages/updatepass';
import Play from './pages/play';
import Start from './pages/start';
import Coin from './admin/coin';
import Data from './pages/data';
import Claimdreques from './admin/claimdreques';
import SignUpAdmin from './admin/SignUp';
import LoginAdmin from './admin/loginAdmin';
import Naviba from './admin/naviba';
import AdminHome from './admin/home';
import Prize from './admin/Prize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './App.css';
import axios from 'axios';
import QuestionAdd from './admin/QuestionAdd';
import Popup from './pages/popup';
import Cupon from './admin/cupon';
import Claimcupom from './pages/claimcupom';
import PieChartComponent from './admin/chart';
import Cupons from './pages/cupons';
import About from './pages/about';
import Questionview from './admin/questionview';
import Qstallcheck from './admin/qstallcheck';
import AddBalance from './admin/addBalance';




const DisplayPath = () => {
  const location = useLocation();
  console.log(location.pathname)

  const user = localStorage.getItem("user");

  const [data, setData] = useState([]);
  const [alert, setAlert] = useState(false);

  useEffect(()=>{
    if(location.pathname !== "/start" && user){
      setAlert(false)
      axios.delete(`${process.env.REACT_APP_API_URL}/delete/by/user/id/for/valid/data/${user}`)
      .then(res =>{
        if(res.data.Status === "OK"){
          localStorage.removeItem("valid")
          // setData("You are quitting the game.")
          // setAlert(true)
        }else{

        }
      })
      .catch(error=>{
        console.log(error);
      })
    }
  },[])


  if(alert){
    return (
      <div>
        <Popup data={data} val={alert} />
      </div>
    );
  }

  
};

const App = () => {
  const [show, setShow] = useState(false);
  const token = localStorage.getItem("ssid");
  const admin = localStorage.getItem("token");
  const valid = localStorage.getItem("valid")

  

  useEffect(() => {
    if (!token) {
      localStorage.removeItem("user");
      localStorage.removeItem("username");
    }
  }, [token]);

  return (
    <div>
      <center>
        {show ? 
          <div>
            <Navi />
            <div className='app-navi-main-cnt'>
              <span onClick={() => setShow(false)}>
                <FontAwesomeIcon icon={faBars} className='app-navi-main-cnt-span-icon' />
              </span>
            </div> 
          </div> 
          
          :

          <div>
            <BrowserRouter>
              <DisplayPath />
              <Routes>
                <Route path='/cupon' element={<Cupons />} />
                <Route path='/' element={<Home />} />
                <Route path='/about' element={<About />} />

                <Route path='/signup' element={token ? <Home /> : <Signup />} />
                <Route path='/login' element={token ? <Home /> : <Login />} />
                <Route path='/forgotpass' element={token ? <Home /> : <Forgotpass />} />
                <Route path='/changepass' element={token ? <Home /> : <Changepass />} />
                <Route path='/navi' element={<Navi />} />
                <Route path='/account' element={token ? <Account /> : <Login />} />
                <Route path='/account/upi' element={token ? <Ac_upi /> : <Login />} />
                <Route path='/account/history' element={token ? <History /> : <Login />} />
                <Route path='/account/pending' element={token ? <Pending /> : <Login />} />
                <Route path='/payment' element={token ? <Payment /> : <Login />} />
                <Route path='/cart' element={token ? <Cart /> : <Login />} />
                <Route path='/cart/history' element={token ? <Carthist /> : <Login />} />
                <Route path='/settings' element={token ? <Settings /> : <Login />} />
                <Route path='/update/password' element={token ? <Updatepass /> : <Login />} />
                <Route path='/play' element={token ? <Play /> : <Login />} />
                <Route path='/claim/cupon' element={token ? <Claimcupom /> : <Login /> } />
                
                {valid &&
                  <Route path='/start' element={token ? <Start /> : <Login />} />
                }
                <Route path='/data' element={token ? <Data /> : <Login />} />
                
                {/* Admin Routes */}
                <Route path='/admin/coins' element={admin ? <Coin /> : <LoginAdmin />} />
                <Route path='/admin/request' element={admin ? <Claimdreques /> : <LoginAdmin />} />
                <Route path='/admin/signup' element={admin ? <Coin /> : <SignUpAdmin />} />
                <Route path='/admin/login' element={admin ? <Coin /> : <LoginAdmin />} />
                <Route path='/admin/home' element={admin ? <AdminHome /> : <LoginAdmin />} />
                <Route path='/admin/prize' element={admin ? <Prize /> : <LoginAdmin />} />
                <Route path='/admin/addquestion' element={admin ? <QuestionAdd /> : <LoginAdmin />} />
                <Route path='/admin/cupon' element={admin ? <Cupon /> : <LoginAdmin /> } />
                <Route path='/admin/chart' element={admin ? <PieChartComponent /> : <LoginAdmin /> } />
                <Route path='/admin/questions' element={admin ? <Questionview /> : <LoginAdmin /> } />
                <Route path='/admin/check' element={admin ? <Qstallcheck/> : <LoginAdmin/> } />
                <Route path='/admin/balance' element={admin ? <AddBalance /> : <LoginAdmin /> } />

                <Route path='*' element={<Error />} />
              </Routes>
            </BrowserRouter>
            <div className='app-navi-main-cnt'>
              <span onClick={() => setShow(true)}>
                <FontAwesomeIcon icon={faBars} className='app-navi-main-cnt-span-icon' />
              </span>
            </div>
          </div>
        }
      </center>
    </div>
  );
};

export default App;


