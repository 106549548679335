import { faArrowRotateForward, faClockRotateLeft, faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React,{useState, useEffect} from 'react'
import api from './api'
import Popup from './popup'
import axios from 'axios'

const Cart = () => {

    const [store_coins, setStore_coins] = useState([]);
    const user = localStorage.getItem("user");
    const [my_Coins, setMy_Coins] = useState([]);
    const [starBal, setStarBal] = useState([]);
    const [alert, setAlert] = useState(false);
    const [data, setData] = useState([]);

    useEffect(()=>{
        GetStoreCoins();
        GetMyCoins();
        GetStarsBala();
    },[])


    const GetStarsBala = () =>{
        setAlert(false)
        api.get(`${process.env.REACT_APP_API_URL}/get/stars/balance/${user}`)
        .then(res =>{
            if(res.data.data){
                setStarBal(res.data.data);
            }else if(res.data.Status === "OKK"){

            }else if(res.data.Logout === "OUT"){
                localStorage.removeItem("ssid");
                window.location.reload();
            }else{
                setData("Something went Wrong")
                setAlert(true)
            }
        }).catch(error =>{
            console.log(error);
        })
    }

    const GetMyCoins = () =>{
        setAlert(false)
        api.get(`${process.env.REACT_APP_API_URL}/get/coins/data/by/id/${user}`)
        .then(res=>{
            if(res.data.data){
                setMy_Coins(res.data.data);
            }
            else if(res.data.Logout === "OUT"){
                localStorage.removeItem("ssid");
                window.location.reload();
            }else{
                setData("Something went Wrong")
                setAlert(true)
            }
    
            }).catch(error =>{
            console.log(error);
            })
    }

    const GetStoreCoins = () =>{
        setAlert(false)
        api.get(`${process.env.REACT_APP_API_URL}/get/coin/data`)
        .then(res=>{
        if(res.data.data){
            setStore_coins(res.data.data);
        }
        else if(res.data.Logout === "OUT"){
            localStorage.removeItem("ssid");
            window.location.reload();
        }else{
            setData("Something went Wrong")
            setAlert(true)
        }

        }).catch(error =>{
        console.log(error);
        })
    }

    


  return (
    <div>
      <center>

        <div className='Home-cnt-01-sub-01'>
            <strong>sta<span>W</span>ro</strong>
            <hr/>
        </div>

        <h1 className='cart-page-main-h1-01'>Cart</h1>

        <div className='cart-page-before-history-cnt-01'>
            <FontAwesomeIcon icon={faClockRotateLeft} onClick={()=>{window.location.href = "/cart/history"}} className='cart-page-before-history-cnt-01-icon-01' />
        </div>

        <div className='cart-page-main-cnt-03'>
            <h1>Stars : <FontAwesomeIcon icon={faStar} className='cart-page-main-cnt-03-icon-01' /><span>{starBal.balance}</span></h1>
        </div>
        
        <div className='cart-page-main-cnt-01'>
            <span className='cart-page-main-cnt-01-span-01' >My <span>Coins</span></span>
            <hr className='cart-page-main-cnt-01-span-01-down-hr-01'/>
            <br/>
            <div className='cart-page-main-cnt-01-sub-cnt-01'>

                {my_Coins.map((data, i) =>{

                    const ClaimCoin = (e) =>{
                        e.preventDefault();
                        setAlert(false);
                        axios.post(`${process.env.REACT_APP_API_URL}/claim/reqst/coins/admin`,{user, id : data._id})
                        .then(res=>{
                            if(res.data.Status === "OK"){
                                GetMyCoins();
                                setData("Request Sent")
                                setAlert(true)
                                
                            }else{
                                setData("Something went Wrong, Try again.")
                                setAlert(true)
                            }
                        })
                        .catch(error=>{
                            console.log(error)
                        })

                    }

                    return(
                        <div key={i} className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01'>
                            <strong className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-strong-01'>{data.title}</strong>
                            <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-01'>
                                <img src={data.img} alt='img' />
                            </div><br/>
                            <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-01'>Valid : <strong>{data.valid}</strong></span><br/>
                            <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-02'>Time : <strong>{data.Time}</strong></span>
                            <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-02'>
                                <strong onClick={ClaimCoin} >Claim</strong>
                            </div>
                        </div>
                    )
                })}
    
            </div>
        </div>
        <br/>

        <div className='cart-page-main-cnt-02'>
            <span className='cart-page-main-cnt-01-span-01'>Store <span>Coins</span></span>
            <hr className='cart-page-main-cnt-01-span-01-down-hr-02'/>
            <br/>

            <div className='cart-page-main-cnt-02-sub-cnt-01'>

                {store_coins.map((data, i) =>{

                    const BuyCoins = (e) =>{
                        setAlert(false);
                        e.preventDefault();
                        api.post(`${process.env.REACT_APP_API_URL}/get/my/conis/get`, { user, id : data._id })
                        .then(res=>{
                            if(res.data.Status === "OK"){
                                GetMyCoins()
                                GetStarsBala()
                                setData("Coin Bought")
                                setAlert(true)
                            }else if(res.data.Status === "Low Bal"){
                                setData("Not Enough Stars")
                                setAlert(true)
                            }else{
                                setData("Something went Wrong")
                                setAlert(true)
                            }
                        })
                        .catch(error =>{
                            console.log(error)
                        })
                    }

                    return(
                        <div key={i} className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01'>
                            <strong className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-strong-01'>{data.title}</strong>
                            <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-01'>
                                <img src={data.img} alt='img' />
                            </div><br/>
                            <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-01'>Valid : <strong>{data.valid}</strong></span><br/>
                            <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-02'>Stars : <strong>{data.stars}</strong></span><br/>
                            <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-02'>
                                <strong onClick={BuyCoins} >Get</strong>
                            </div>
                        </div>
                    )
                })}

                {/* <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01'>
                    <strong className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-strong-01'>Zomato</strong>
                    <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-01'>
                        <img src='https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse3.mm.bing.net%2Fth%3Fid%3DOIP.ZpNQJ1Tu0C6nkAxNLh3BYQHaEK%26pid%3DApi&f=1&ipt=990356f937c5830ddebba595f9dd61acbd35ca6e3e5132f7d6b66bc3149fb1c2&ipo=images' alt='img' />
                    </div><br/>
                    <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-01'>Valid : <strong>01 Month</strong></span><br/>
                    <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-02'>Stars : <strong>12</strong></span><br/>
                    <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-02'>
                        <strong>Get</strong>
                    </div>
                </div>

                <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01'>
                    <strong className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-strong-01'>Zomato</strong>
                    <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-01'>
                        <img src='https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse3.mm.bing.net%2Fth%3Fid%3DOIP.ZpNQJ1Tu0C6nkAxNLh3BYQHaEK%26pid%3DApi&f=1&ipt=990356f937c5830ddebba595f9dd61acbd35ca6e3e5132f7d6b66bc3149fb1c2&ipo=images' alt='img' />
                    </div><br/>
                    <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-01'>Valid : <strong>01 Month</strong></span><br/>
                    <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-02'>Stars : <strong>12</strong></span><br/>
                    <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-02'>
                        <strong>Get</strong>
                    </div>
                </div>

                <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01'>
                    <strong className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-strong-01'>Zomato</strong>
                    <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-01'>
                        <img src='https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse3.mm.bing.net%2Fth%3Fid%3DOIP.ZpNQJ1Tu0C6nkAxNLh3BYQHaEK%26pid%3DApi&f=1&ipt=990356f937c5830ddebba595f9dd61acbd35ca6e3e5132f7d6b66bc3149fb1c2&ipo=images' alt='img' />
                    </div><br/>
                    <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-01'>Valid : <strong>01 Month</strong></span><br/>
                    <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-02'>Stars : <strong>12</strong></span><br/>
                    <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-02'>
                        <strong>Get</strong>
                    </div>
                </div>

                <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01'>
                    <strong className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-strong-01'>Zomato</strong>
                    <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-01'>
                        <img src='https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse3.mm.bing.net%2Fth%3Fid%3DOIP.ZpNQJ1Tu0C6nkAxNLh3BYQHaEK%26pid%3DApi&f=1&ipt=990356f937c5830ddebba595f9dd61acbd35ca6e3e5132f7d6b66bc3149fb1c2&ipo=images' alt='img' />
                    </div><br/>
                    <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-01'>Valid : <strong>01 Month</strong></span><br/>
                    <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-02'>Stars : <strong>12</strong></span><br/>
                    <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-02'>
                        <strong>Get</strong>
                    </div>
                </div>
                <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01'>
                    <strong className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-strong-01'>Zomato</strong>
                    <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-01'>
                        <img src='https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse3.mm.bing.net%2Fth%3Fid%3DOIP.ZpNQJ1Tu0C6nkAxNLh3BYQHaEK%26pid%3DApi&f=1&ipt=990356f937c5830ddebba595f9dd61acbd35ca6e3e5132f7d6b66bc3149fb1c2&ipo=images' alt='img' />
                    </div><br/>
                    <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-01'>Valid : <strong>01 Month</strong></span><br/>
                    <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-02'>Stars : <strong>12</strong></span><br/>
                    <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-02'>
                        <strong>Get</strong>
                    </div>
                </div>
                <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01'>
                    <strong className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-strong-01'>Zomato</strong>
                    <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-01'>
                        <img src='https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse3.mm.bing.net%2Fth%3Fid%3DOIP.ZpNQJ1Tu0C6nkAxNLh3BYQHaEK%26pid%3DApi&f=1&ipt=990356f937c5830ddebba595f9dd61acbd35ca6e3e5132f7d6b66bc3149fb1c2&ipo=images' alt='img' />
                    </div><br/>
                    <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-01'>Valid : <strong>01 Month</strong></span><br/>
                    <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-02'>Stars : <strong>12</strong></span><br/>
                    <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-02'>
                        <strong>Get</strong>
                    </div>
                </div>
                <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01'>
                    <strong className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-strong-01'>Zomato</strong>
                    <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-01'>
                        <img src='https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse3.mm.bing.net%2Fth%3Fid%3DOIP.ZpNQJ1Tu0C6nkAxNLh3BYQHaEK%26pid%3DApi&f=1&ipt=990356f937c5830ddebba595f9dd61acbd35ca6e3e5132f7d6b66bc3149fb1c2&ipo=images' alt='img' />
                    </div><br/>
                    <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-01'>Valid : <strong>01 Month</strong></span><br/>
                    <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-02'>Stars : <strong>12</strong></span><br/>
                    <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-02'>
                        <strong>Get</strong>
                    </div>
                </div>
                <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01'>
                    <strong className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-strong-01'>Zomato</strong>
                    <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-01'>
                        <img src='https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse3.mm.bing.net%2Fth%3Fid%3DOIP.ZpNQJ1Tu0C6nkAxNLh3BYQHaEK%26pid%3DApi&f=1&ipt=990356f937c5830ddebba595f9dd61acbd35ca6e3e5132f7d6b66bc3149fb1c2&ipo=images' alt='img' />
                    </div><br/>
                    <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-01'>Valid : <strong>01 Month</strong></span><br/>
                    <span className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-span-02'>Stars : <strong>12</strong></span><br/>
                    <div className='cart-page-main-cnt-01-sub-cnt-01-sub-cnt-01-sub-02'>
                        <strong>Get</strong>
                    </div>
                </div> */}

            </div>

        </div>
        <div style={{height : "50px"}}>

        </div>
        {alert &&
            <Popup data={data} val={alert} />
        }
        
      </center>
    </div>
  )
}

export default Cart
