import React, { useEffect, useState } from 'react'
import Ac_upi from './Account/ac_upi'
import api from './api';

const Data = () => {

    const user = localStorage.getItem("user")
    const [info, setInfo] = useState([]);
    const [isdata, setIsData] = useState([]);
    const [isBank, setIsBank] = useState(false);
    useEffect(()=>{
        GetData()
    },[])

    const GetData = () =>{
        api.get(`${process.env.REACT_APP_API_URL}/get/bank/account/data/${user}`)
        .then(res =>{
            if(res.data.data){
                setIsData(true)
                setInfo(res.data.data)
                const dat = res.data.data
                if(dat.type === "UPI"){
                    setIsBank(false)
                }else{
                    setIsBank(true)
                }
            }else if(res.data.Status === "No"){
                setIsData(false);
            }else if(res.data.Logout === "OUT"){
                localStorage.removeItem("ssid");
                window.location.reload()
            }
        })
        .catch(error =>{
            console.log(error)
        })

    }

  return (
    <div>
        <center>
        <div className='Home-cnt-01-sub-01'>
            <strong>sta<span>W</span>ro</strong>
            <hr/>
        </div>
        <div className='data-page-main-cnt-01'>
            <span>Username : <strong>DarshanC_kick</strong></span><br/><br/>
            <span>Name : <strong>Darshan</strong></span><br/><br/>
            <span>Email : <strong>darshan@gmail.com</strong></span><br/>
        </div>
        <br/>
        <hr style={{width : "80%"}}/>
        <div className='data-page-main-cnt-02'>
            {isdata ? 
            <div>
                {isBank ?
                    <div className='data-apge-main-cnt-02-sub-01'>
                        <h3>Bank</h3>
                        {info.type === "BANK" &&
                            <div>
                                <span>Account Holder Name : <strong>{info.ac_h_nme}</strong></span><br/>
                                <span>Account Number : <strong>{info.Acc_no}</strong></span><br/>
                                <span>Bank Name : <strong>{info.bank_nme}</strong></span><br/>
                                <span>IFSC : <strong>{info.ifsc}</strong></span><br/>
                            </div>
                        }
                    </div>
                    :
                    <div className='data-apge-main-cnt-02-sub-01'>
                        <h3>UPI</h3>
                        {info.type === "UPI" && 
                        <div>
                            <span>Account Holder Name : <strong>{info.ac_h_nme}</strong></span><br/>
                            <span>UPI ID : <strong>{info.Acc_no}</strong></span><br/>
                            <span>App : <strong>{info.app}</strong></span><br/>
                        </div>}
                    </div>
                }
            </div>
            :
            <div>
                <h2>No Bank / UPI Account Linked</h2>
            </div>
            }
        </div>
        </center>   
    </div>
  )
}

export default Data
