import { faArrowAltCircleDown, faArrowDown, faArrowDownAZ, faArrowDownLong, faArrowDownUpAcrossLine, faArrowUpAZ, faBank, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import api from "../pages/api"

const Account = () => {

  const [balance, setBalance] = useState([]);
  const [btn1, setBtn1] = useState([]);

  const user = localStorage.getItem("user");

  useEffect(()=>{
    GetBalance()
  },[])

  const New = () =>{
    api.post(`${process.env.REACT_APP_API_URL}/get/balance/new/data`,{user})
    .then(res=>{
      if(res.data.Status === "OK"){
        GetBalance();
      }else{
        alert("Amoun not Credited")
      }
    }).catch(error =>{
      console.log(error)
    })
  }

  const GetBalance = () =>{
    api.get(`${process.env.REACT_APP_API_URL}/get/acount/balence/${user}`)
    .then(res =>{
      if(res.data.data){
        setBtn1(true)
        setBalance(res.data.data);
      }else if(res.data.Status === "NO"){
        setBtn1(false);
      }else if(res.data.Logout === "OUT"){
        localStorage.removeItem("ssid");
        window.location.reload()
      }else{

      }
    }).catch(error =>{
      console.log(error);
    })
  }

  return (
    <div>
      <center>
        <div className='Home-cnt-01-sub-01'>
            <strong>sta<span>W</span>ro</strong>
            <hr/>
        </div>
        <div className='account-main-cnt-01'>
            <span className='account-main-cnt-01-span-01' onClick={New} >Bank Account</span>
            
            <div className='account-main-cnt-01-sub-01'>
              {btn1 === false && <button onClick={New}>Get Free ₹15</button>}
              {btn1 === true && <span className='account-main-cnt-01-sub-01-sub-span-01'>Account Balance ₹<span>{balance.balance}.00</span> </span> }
                
                <h2><FontAwesomeIcon icon={faPlusCircle} className='account-main-cnt-01-sub-01-sub-h2-icon-01' onClick={()=>{window.location.href='/payment'}} /></h2>
            </div>
        </div>
        <br/>

        <div className='account-main-cnt-02'>

          {/* AC/UPI */}
          <div className='account-main-cnt-02-sub-cnt-01' onClick={()=>{window.location.href='/account/upi'}}>
            <span>
              <FontAwesomeIcon icon={faBank} className='account-main-cnt-02-sub-cnt-01-sub-icon-01' />
            </span><br/><br/>
            <span className='account-main-cnt-02-sub-cnt-01-span-01'>AC/UPI</span>
          </div>


          {/* History */}
          <div className='account-main-cnt-02-sub-cnt-01' onClick={()=>{window.location.href='/account/history'}}>
            <span>
              <FontAwesomeIcon icon={faArrowUpAZ} className='account-main-cnt-02-sub-cnt-01-sub-icon-01' />
            </span><br/><br/>
            <span className='account-main-cnt-02-sub-cnt-01-span-01'>History</span>
          </div>


          {/* Pending */}
          <div className='account-main-cnt-02-sub-cnt-01' onClick={()=>{window.location.href='/account/pending'}}>
            <span>
              <FontAwesomeIcon icon={faArrowDownUpAcrossLine} className='account-main-cnt-02-sub-cnt-01-sub-icon-01' />
            </span><br/><br/>
            <span className='account-main-cnt-02-sub-cnt-01-span-01'>Pending</span>
          </div>
          


        </div>

      </center>
    </div>
  )
}

export default Account
