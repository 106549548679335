import React, { useEffect, useState } from 'react'
import Popup from './popup';
import axios from 'axios';
import api from './api';

const Start = () => {

  const num = 3;
  const [data, setData] = useState([]);
  const [alert, setAlert] = useState(false);
  const user = localStorage.getItem("user")
  const [QData, setQData] = useState([])


  useEffect(()=>{
    GetQuestion()
  },[])

  const GetQuestion = () =>{
    api.get(`${process.env.REACT_APP_API_URL}/get/question/no/by/user/name/${user}`)
    .then(res=>{
      if(res.data.data){
        setQData(res.data.data);
      }else if(res.data.Status === "BAD"){
        window.location.href ='/play'
      }else if(res.data.Logout === "OUT"){
        localStorage.removeItem("ssid");
        window.location.reload()
      }else{

      }
    }).catch(error=>{
      console.log(error)
    })
  }


  //quit Game Function

  const QuitGame = (e) =>{
    e.preventDefault();
    setAlert(false)
      axios.delete(`${process.env.REACT_APP_API_URL}/delete/by/user/id/for/valid/data/${user}`)
      .then(res =>{
        if(res.data.Status === "OK"){
          setData("You are quitting the game.")
          setAlert(true)
          window.location.href='/'
        }else{
          setData("Something went Wrong")
          setAlert(true)
          window.location.href='/'
        }
      })
      .catch(error=>{
        console.log(error);
      })
  }

  function VerifyAnswer(answer){
    setAlert(false)
    axios.post(`${process.env.REACT_APP_API_URL}/verify/answer/question/number`,{answer, user, id : QData._id})
    .then(res =>{
      if(res.data.Status === "OK"){
        GetQuestion()
      }else if(res.data.Status === "OKK"){
        const id = res.data.id
        const rank = res.data.rank
        window.location.href=`/claim/cupon?id=${id}&rank=${rank}`
      }else if(res.data.Status === "STARS"){
        alert(`You won the Game, and you got ${res.data.stars} stars `)
        window.location.href='/cart'
      }
      else{
        setData("Wrong Answer")
        setAlert(true)
        window.location.href='/play'
      }
    }).catch(error =>{
      console.log(error)
    })
  }  


  return (
    <div>
      <center>
      <div className='Home-cnt-01-sub-01'>
            <strong>sta<span>W</span>ro</strong>
            <hr/>
        </div>
                 
        <span className='game_start-span-01' >Seconds : <strong className={num < 5 ? "game_start-span-01-strong-02" : "game_start-span-01-strong-01"} >{num}</strong> </span>
        {QData.Question &&  <div className='game_start-main-cnt-01'>
            
              {QData.Qno &&
                <span className='game_start-main-cnt-01-span-01'><span className='game_start-main-cnt-01-span-01-span-01' > {QData.Qno}</span>  : {QData.Question}.</span> 
              }
              
            <br/>
            {QData.img !== "" &&
            <div className='game_start-main-cnt-01-img-cnt-01'>
              <img src={QData.img} alt='image' />
            </div>}
            <br/>

            <div className='game_start-main-cnt-01-sub-cnt-01'>
              
              
              <button onClick={()=>{VerifyAnswer("a")}}>
                {QData.a}
              </button>

              <button onClick={()=>{VerifyAnswer("b")}}>
              {QData.b}
              </button>

            </div>

            <div className='game_start-main-cnt-01-sub-cnt-01'>
              
              <button onClick={()=>{VerifyAnswer("c")}}>
              {QData.c}
              </button>
              
              <button onClick={()=>{VerifyAnswer("d")}}>
              {QData.d}
              </button>

            </div>
            <div className='game_start-main-cnt-01-sub-cnt-02'>
              <button onClick={QuitGame}>
                Quit
              </button>
            </div>
          </div>}
      </center>
      {alert &&
        <Popup data={data} val={alert} />
      }

      <div style={{height : "50px"}}>

      </div>
    </div>
  )
}

export default Start
